<script setup>
import { computed, ref } from 'vue';

import AppMenuItem from './AppMenuItem.vue';
import useAuthStore from '@/store/authStore';

const allMenus = ref([
    {
        label: 'Home',
        items: [{ label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/' }],
        permissions: ['admin']
    },
    {
        label: 'Design 2D',
        items: [
            { label: 'Crawl Task', icon: 'pi pi-fw pi-map', to: '/crawl-2d/tasks' },
            { label: 'Design 2D', icon: 'pi pi-fw pi-instagram', to: '/crawl-2d/designs' },
        ],
        permissions: ['admin']
    },
    {
        label: 'Design 3D',
        items: [
            { label: 'Crawl Task', icon: 'pi pi-fw pi-map', to: '/crawl-3d/tasks' },
            { label: 'Design 3D', icon: 'pi pi-fw pi-instagram', to: '/crawl-3d/designs' },
        ],
        permissions: ['admin']
    },
    {
        label: 'Blacklist',
        items: [
            { label: 'TM Keyword', icon: 'pi pi-fw pi-tags', to: '/blacklist/keywords' },
            { label: 'TM Categories', icon: 'pi pi-fw pi-tag', to: '/blacklist/categories' },
            { label: 'Store Blacklist', icon: 'pi pi-fw pi-lock', to: '/blacklist/stores' }
        ],
        permissions: ['admin']
    },
    {
        label: 'Cron Job',
        items: [
            { label: 'Job', icon: 'pi pi-fw pi-tags', to: '/cron-job/jobs' },
        ],
        permissions: ['admin']
    },
    {
        label: 'UI Components',
        items: [
            { label: 'Form Layout', icon: 'pi pi-fw pi-id-card', to: '/uikit/formlayout' },
            { label: 'Input', icon: 'pi pi-fw pi-check-square', to: '/uikit/input' },
            { label: 'Button', icon: 'pi pi-fw pi-mobile', to: '/uikit/button', class: 'rotated-icon' },
            { label: 'Table', icon: 'pi pi-fw pi-table', to: '/uikit/table' },
            { label: 'List', icon: 'pi pi-fw pi-list', to: '/uikit/list' },
            { label: 'Tree', icon: 'pi pi-fw pi-share-alt', to: '/uikit/tree' },
            { label: 'Panel', icon: 'pi pi-fw pi-tablet', to: '/uikit/panel' },
            { label: 'Overlay', icon: 'pi pi-fw pi-clone', to: '/uikit/overlay' },
            { label: 'Media', icon: 'pi pi-fw pi-image', to: '/uikit/media' },
            { label: 'Menu', icon: 'pi pi-fw pi-bars', to: '/uikit/menu' },
            { label: 'Message', icon: 'pi pi-fw pi-comment', to: '/uikit/message' },
            { label: 'File', icon: 'pi pi-fw pi-file', to: '/uikit/file' },
            { label: 'Chart', icon: 'pi pi-fw pi-chart-bar', to: '/uikit/charts' },
            { label: 'Timeline', icon: 'pi pi-fw pi-calendar', to: '/uikit/timeline' },
            { label: 'Misc', icon: 'pi pi-fw pi-circle', to: '/uikit/misc' }
        ],
        permissions: ['cs','admin']
    },
    {
        label: 'Pages',
        icon: 'pi pi-fw pi-briefcase',
        to: '/pages',
        permissions: ['cs'],
        items: [
            {
                label: 'Landing',
                icon: 'pi pi-fw pi-globe',
                to: '/landing'
            },
            {
                label: 'Auth',
                icon: 'pi pi-fw pi-user',
                items: [
                    {
                        label: 'Login',
                        icon: 'pi pi-fw pi-sign-in',
                        to: '/auth/login'
                    },
                    {
                        label: 'Error',
                        icon: 'pi pi-fw pi-times-circle',
                        to: '/auth/error'
                    },
                    {
                        label: 'Access Denied',
                        icon: 'pi pi-fw pi-lock',
                        to: '/auth/access'
                    }
                ]
            },
            {
                label: 'Crud',
                icon: 'pi pi-fw pi-pencil',
                to: '/pages/crud'
            },
            {
                label: 'Not Found',
                icon: 'pi pi-fw pi-exclamation-circle',
                to: '/pages/notfound'
            },
            {
                label: 'Empty',
                icon: 'pi pi-fw pi-circle-off',
                to: '/pages/empty'
            }
        ]
    },
    {
        label: 'Hierarchy',
        permissions: ['cs'],
        items: [
            {
                label: 'Submenu 1',
                icon: 'pi pi-fw pi-bookmark',
                items: [
                    {
                        label: 'Submenu 1.1',
                        icon: 'pi pi-fw pi-bookmark',
                        items: [
                            { label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-bookmark' },
                            { label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-bookmark' },
                            { label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-bookmark' }
                        ]
                    },
                    {
                        label: 'Submenu 1.2',
                        icon: 'pi pi-fw pi-bookmark',
                        items: [{ label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-bookmark' }]
                    }
                ]
            },
            {
                label: 'Submenu 2',
                icon: 'pi pi-fw pi-bookmark',
                items: [
                    {
                        label: 'Submenu 2.1',
                        icon: 'pi pi-fw pi-bookmark',
                        items: [
                            { label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-bookmark' },
                            { label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-bookmark' }
                        ]
                    },
                    {
                        label: 'Submenu 2.2',
                        icon: 'pi pi-fw pi-bookmark',
                        items: [{ label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-bookmark' }]
                    }
                ]
            }
        ]
    },
    {
        label: 'Get Started',
        permissions: ['supporter'],
        items: [
            {
                label: 'Documentation',
                icon: 'pi pi-fw pi-book',
                to: '/documentation',
                permissions: ['admin']
            },
            {
                label: 'View Source',
                icon: 'pi pi-fw pi-github',
                url: 'https://github.com/jonaskahn/enhanced-sakai-vue',
                target: '_blank'
            }
        ]
    }
]);

function containsAny(arr1, arr2) {
    return arr1.some((element) => {
        return arr2.indexOf(element) !== -1;
    });
}

function hasPermission(menu) {
    if (menu.permissions === undefined || menu.permissions === null || menu.permissions.length === 0) {
        console.log("has permission")
        return true;
    }
    const authStore = useAuthStore()
    return containsAny(menu.permissions, authStore.getPermissions.value);
}

function hasSubMenu(menu) {
    if (menu.items === null|| menu.items.length === 0) {
        return false
    }
    const authStore = useAuthStore()
    return menu.items.some((subMenu) => {
        if (subMenu.permissions === undefined || subMenu.permissions === null || subMenu.permissions.length === 0) {
            return true
        }
        return containsAny(subMenu.permissions, authStore.getPermissions.value);
    })
}

const activeMenus = computed(() => {
    return allMenus.value.filter((menu) => {
        return hasPermission(menu) && hasSubMenu(menu);
    });
});
</script>

<template>
    <ul class="layout-menu">
        <template v-for="(item, i) in activeMenus" :key="item">
            <app-menu-item v-if="!item.separator" :index="i" :item="item"></app-menu-item>
            <li v-if="item.separator" class="menu-separator"></li>
        </template>
    </ul>
</template>

<style lang="scss" scoped></style>
